export const tonnesPerMangrove = 0.3

export function livingSpace (squareMeters) {
    return (175 * squareMeters) / Math.log(1.2 * squareMeters)
}

/**
 * Every ten thousand driving kilometers produce 1600 kg of CO2 emissions
 */
export function drivingEmissions (kilometers) {
    return 0.16 * kilometers
}

/**
 * Every flight hour produces 94 kg of CO2 emissions
 */
export function flightEmissions (hours) {
    return 94 * hours
}

export function mangroveSequestration (emissions) {
    const tonnesYearly = emissions / 1000 * 12
    const result = tonnesYearly / tonnesPerMangrove
    // console.debug('Mangrove sequestration:', {emissions, tonnesYearly, result})

    return result
}

// Graphing

export function treeLeft (ratio, scale = 0.82) {
    return (ratio * 100) * scale
}

export function treeSize (ratio, max = 4, min = 0.25) {
    return ratio * (max - min) + min
}

export function treeStyle (ratio) {
    return [
        `left: ${treeLeft(ratio)}%`,
        `font-size: ${treeSize(ratio)}cm`,
    ].join('; ')
}
