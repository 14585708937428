<script>
    import { dropWhile, filter, head, identity, includes } from 'rambdax'
    import { labels } from '@landing/lib/calculator/data'

    export let name
    export let id = name
    export let list = false
    export let min = 0
    export let max = 100
    export let step = 1
    export let value = 0
    export let unit = null

    function tooltip (value, unit) {
        let tip = ''

        if (includes(name, ['diet', 'shop'])) {
            tip = head(dropWhile(
                (label) => { return parseInt(label.value) < parseInt(value) },
                labels[name],
            )).label
        } else {
            tip = filter(identity, [value, unit]).join(' ')
        }

        return tip
    }
</script>

<input type="range"
       bind:value={value}
       on:change
       {id}
       {name}
       {list}
       {max}
       {min}
       {step}
       class="tooltip"
       data-tooltip={ tooltip(value, unit) }
       />

<style lang="scss">
    @import "../../style/variables";
    @import "../../style/input_range_reset";
    @import "../../style/input_range";

    .tooltip::after {
        color: $ignition-yellow;
        background-color: rgba($dark-color, 0.75);
        height: auto;
        bottom: 1.25rem;
    }
</style>
