import {
    curry,
    last,
    length,
    map,
    mergeAll,
    repeat,
    zipObj,
    zip,
} from 'rambdax'

export function scale (start = 0, stop = 100, step = 10) {
    const result = []
    let value = start

    while (value <= stop) {
        result.push(value)
        value += step
    }

    return result
}

/**
 * Return given keys mapping to each value as list of objects
 *
 * @example
 * ```
 * withKeysUncurried(['a', 'b'], [1, 2, 3])
 *
 * // [{"a": 1, "b": 1},
 * //  {"a": 2, "b": 2},
 * //  {"a": 3, "b": 3}]
 * ```
 */
export function withKeysUncurried (keys, values) {
    const result = map((value) => {
        return zipObj(keys, repeat(value, length(keys)))
    }, values)

    return result
}

export const withKeys = curry(withKeysUncurried)

export const options = withKeys(['value', 'label'])

export const textOptions = (labels, scale) => {
    return map(
        mergeAll,
        zip(withKeys(['value'], scale), labels),
    )
}

export function unitOptions (unit, list) {
    const result = options(list)
    last(result).label += ' ' + unit

    return result
}
