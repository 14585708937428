import {
    curry,
    head,
    last,
    map,
    sum,
    tail,
    values,
} from 'rambdax'

import { drivingEmissions, flightEmissions, livingSpace } from '@landing/lib/calculator/co2'
import { textOptions, options } from '@landing/lib/calculator/options'
import { scale } from '@landing/lib/utils'

export const ranges = [
    { name: 'fly', verb: 'flights', title: 'How much do you <b>fly</b>?', unit: 'hours/year' },
    { name: 'home', verb: 'housing', title: 'How big is your <b>home?</b>', unit: 'm²/person' },
    { name: 'drive', verb: 'driving', title: 'How much do you <b>drive</b>?', unit: 'km/year' },
    { name: 'diet', verb: 'diet', title: 'How is your <b>diet?</b>' },
    { name: 'shop', verb: 'shopping', title: 'Do you <b>shop</b> a lot?' },
]

export const eventRanges = [
    { name: 'fly', verb: 'flights', title: 'How much do you <b>fly</b>?', unit: 'hours/year' },
    { name: 'events', verb: 'events', title: 'How much do you <b>organise events</b>?', unit: 'hours/year' },
]

export const average = {
    diet: 1200,
    drive: 14000,
    fly: 10,
    home: 25,
    shop: 2500,
}

export const regionalAverage = {
    eu: 7000,
    us: 15000,
    world: 4800,
    india: 1800,
    africa: 800,
}

/* eslint-disable key-spacing, no-multi-spaces */
export const scales = {
    diet: [800,  900,  1200,  1800],
    drive: scale(0,    50000, 10000),
    fly:   scale(0,    100,   20),
    home:  scale(10,   100,   30),
    shop:  scale(1100, 3900,  700),
}

export const steps = {
    diet: 100,
    drive: 1000,
    home: 1,
    fly: 1,
    shop: 10,
}

const dietLabels = [
    { label: 'Vegan' },
    { label: 'Vegetarian', css: 'grow-3' },
    { label: 'No beef', css: 'grow-6' },
    { label: 'Meat' },
]

const shoppingLabels = [
    { label: 'Very little' },
    { label: 'Less' },
    { label: 'Average' },
    { label: 'More' },
    { label: 'Very much' },
]

export const labels = {
    diet: textOptions(dietLabels, scales.diet),
    drive: options(scales.drive),
    fly: options(scales.fly),
    home: options(scales.home),
    shop: textOptions(shoppingLabels, scales.shop),
}

export const emissions = {
    ...scales,
    home: map(livingSpace, scales.home),
    drive: map(drivingEmissions, scales.drive),
    fly: map(flightEmissions, scales.fly),
}

const domain = {
    min: 0, // sum(values(map(head, emissions))),
    max: sum(values(map(last, emissions))) / 1.7, // Make default about centered
}

const ratioForDomain = curry((domain, total) => {
    return (total - domain.min) / (domain.max - domain.min)
})

export const ratio = ratioForDomain(domain)
